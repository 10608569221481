import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faSearch,
  faTruck,
  faTimes,
  faExclamationCircle,
  faChevronLeft,
  faChevronRight,
  faRocket,
  faArrowRight,
  faCheck,
  faCaretDown,
  faSync,
  faUser,
  faPencilAlt,
  faTrash,
  faStar,
  faBan,
  faBullseye,
  faAddressBook,
  faMapMarker,
  faMapMarkerAlt,
  faBoxOpen,
  faRedo,
  faFileInvoiceDollar,
  faDollarSign,
  faDownload,
  faFileExport,
  faMousePointer,
  faDrawPolygon,
  faGlobeAfrica,
  faCopy,
  faCaretUp,
  faCalculator,
  faListAlt,
  faCamera,
  faComments,
  faPeopleArrows,
  faEnvelope,
  faLock,
  faDesktop,
  faPrint,
  faBarcode,
  faPercentage,
  faPlusCircle,
  faPaperPlane,
  faCog,
  faCrosshairs,
  faPlus,
  faTruckLoading,
  faStickyNote,
  faFileImport,
  faUsers,
  faChartBar,
  faMap,
  faCogs,
  faList,
  faInfoCircle,
  faShippingFast,
  faMoneyBill,
  faUserClock,
  faExternalLinkAlt,
  faCaretLeft,
  faCaretRight,
  faSignInAlt,
  faUpload,
  faFilePdf,
  faTerminal,
  faImage,
  faScroll,
  faLaptopCode,
  faFilter,
  faRedoAlt,
  faExclamationTriangle,
  faPaintBrush,
  faArrowDown,
  faFileInvoice,
  faGlobe,
  faShield,
  faParachuteBox,
  faTowerCell,
  faSmile,
  faKey,
  faCalendar,
  faDoorOpen,
  faClock,
  faTimesCircle,
  faBrain,
  faPlay,
  faBell,
  faTools,
  faMapLocationDot,
  faBox,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faStore,
  faEye,
  faCheckCircle,
  faTrashRestore,
  faFileImage,
  faTags,
  faFileZipper,
  faHandHoldingHeart,
  faChartLine,
  faAngleLeft,
  faAngleRight,
  faMessage,
  faLocationDot,
  faBuilding,
  faUserCircle,
  faBellSlash
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBars,
  faSearch,
  faTruck,
  faTimes,
  faExclamationCircle,
  faChevronLeft,
  faChevronRight,
  faRocket,
  faArrowRight,
  faCheck,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faSync,
  faUser,
  faPencilAlt,
  faTrash,
  faStar,
  faBan,
  faPlusCircle,
  faPaperPlane,
  faBullseye,
  faAddressBook,
  faCog,
  faCogs,
  faCrosshairs,
  faMapMarker,
  faMapMarkerAlt,
  faPlus,
  faBoxOpen,
  faRedo,
  faTruckLoading,
  faFileInvoiceDollar,
  faDollarSign,
  faStickyNote,
  faTimes,
  faUpload,
  faDownload,
  faFileExport,
  faFileImport,
  faMousePointer,
  faDrawPolygon,
  faGlobeAfrica,
  faCopy,
  faCaretUp,
  faCalculator,
  faExclamationCircle,
  faExclamationTriangle,
  faListAlt,
  faCamera,
  faComments,
  faPeopleArrows,
  faEnvelope,
  faLock,
  faDesktop,
  faPaperPlane,
  faRocket,
  faPrint,
  faBarcode,
  faPercentage,
  faUsers,
  faChartBar,
  faMap,
  faList,
  faInfoCircle,
  faShippingFast,
  faMoneyBill,
  faUserClock,
  faExternalLinkAlt,
  faSignInAlt,
  faFilePdf,
  faImage,
  faScroll,
  faLaptopCode,
  faFilter,
  faRedoAlt,
  faPaintBrush,
  faArrowDown,
  faFileInvoice,
  faGlobe,
  faShield,
  faParachuteBox,
  faTerminal,
  faTowerCell,
  faMessage,
  faSmile,
  faKey,
  faCalendar,
  faDoorOpen,
  faClock,
  faTimesCircle,
  faBrain,
  faPlay,
  faBell,
  faTools,
  faMapLocationDot,
  faBox,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faStore,
  faEye,
  faCheckCircle,
  faTrashRestore,
  faFileImage,
  faTags,
  faFileZipper,
  faHandHoldingHeart,
  faChartLine,
  faAngleRight,
  faAngleLeft,
  faLocationDot,
  faBuilding,
  faUserCircle,
  faBellSlash
);
